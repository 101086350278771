import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { 
    Divider, Button, Grid, Typography, Paper, Box
} from '@mui/material';
import CustomAlert from '../Components/CustomAlert'
import CustomImageList from '../Components/CustomImageList';
import CustomDatePicker from '../Components/DatePicker';
import dayjs from 'dayjs';
import Map from '../Components/Map';
import Constants from '../Utils/Constants';
import CustomTextField from '../Components/CustomTextField';
import ReactHtmlParser from 'react-html-parser';
import ServiciosList from '../Components/ServiciosList';
// import CheckoutConekta from '../Components/CheckoutConekta';
import reservationService from '../Services/ReservationService';
import Loading from '../Components/Loading';
import { useNavigate } from 'react-router-dom';
import functions from '../Utils/Functions';
function ReservationDetail() {
    const [usuarioReserva, setUsuarioReserva] = useState(false)
    const [textFieldValue, setTextFieldValue] = useState({nomCliente: "", desTelefono: "", desCorreo: ""})
    const location = useLocation();
    const navigate = useNavigate();
    const { nomCliente, desTelefono, desCorreo} = textFieldValue
    const messageType = Constants.MESSAGE_TYPE;
    const [objetoDepartamento, setObjetoDepartamento] = useState({
        idDepto: '',
        tituloDepartamento: '',
        desDepartamento: '',
        impCosto: '',
        impMtto: '',
        numHuespedes: '',
        fotografias: [],
        servicios: [],
        impCostoTotal: '',
        totalNoches: '',
        desReglas:'',
        desSeguridad: '',
        desPoliticas: '',
        coordIniciales: '',
        prcAumento: 0
    })
    const [objetoUbicacion, setObjetoUbicacion] = useState({
        latitud: '',
        longitud: '',
    })
    const alertRef = useRef()
    const [fechaInicial, setFechaInicial] = useState(dayjs());
    const [fechaFinal, setFechaFinal] = useState(dayjs().add(1, 'day'));
    //const [objetoConekta, setObjetoConekta] = useState(null);
    const [loading, setLoading] = useState(false);

    const generarPago = async () => {
        if (!nomCliente) {
            showAlertMessage("Nombre del húesped es requerido!", messageType.WARNING)
            return
        }
        if (!desTelefono) {
            showAlertMessage("Télefono del húesped es requerido!", messageType.WARNING)
            return
        }
        if (!desCorreo) {
            showAlertMessage("Correo del húesped es requerido!", messageType.WARNING)
            return
        }
        onFinalizaPagoConekta(null)
        /*
        setLoading(true)
        const objetoConekta = {
            "nombreHuesped": nomCliente,
            "correoHuesped": desCorreo,
            "telefonoHuesped": desTelefono,
            "nomDepartamento": objetoDepartamento.tituloDepartamento,
            "impDepartamento": objetoDepartamento.impCostoTotal
        }
        
        try {
            const response = await reservationService.obtenerCheckoutConekta(objetoConekta);
            setLoading(false)
            if (response.data.success) {
                setObjetoConekta(response.data.response)
            } else {
                setObjetoConekta(null)
                showAlertMessage(Constants.NO_INFO_MESSAGE, messageType.WARNING)
            }
        } catch (error) {
            setLoading(false)
            showAlertMessage(Constants.GENERIC_MESSAGE, messageType.ERROR)
        }*/
    }

    const showAlertMessage = (message, alertType) => {
        if (alertRef.current) {
            alertRef.current.showAlert(message, alertType)
        }
    }

    const calculoImportes = (date) => {
        setFechaFinal(date)
        let fecInicial = dayjs(fechaInicial.format("YYYY-MM-DD"))
        let fecFinal = dayjs(date.format("YYYY-MM-DD"))
        let diasReserva = fecFinal.diff(fecInicial, 'day')
        setObjetoDepartamento({
            ...objetoDepartamento,
            totalNoches: diasReserva, impCostoTotal: (diasReserva * objetoDepartamento.impCosto) + objetoDepartamento.impMtto})
    }

    const onCambiaValorTxt = (e) => {
        const {name, value} = e.target
        setTextFieldValue((prev) => ({
          ...prev,
          [name]: value,
        }))
    }

    const onFinalizaPagoConekta = async (data) => {
        setLoading(true)
        const requestMovimiento = {
            'idDepartamento': objetoDepartamento.idDepto,
            'nombreHuesped': nomCliente,
            'correoHuesped': desCorreo,
            'telefonoHuesped': desTelefono,
            'fecInicio': fechaInicial.format("YYYY-MM-DD"),
            'fecFin': fechaFinal.format("YYYY-MM-DD"),
            'impCostoDiario': objetoDepartamento.impCosto,
            'impCostoTotal': objetoDepartamento.impCostoTotal,
            'prcAumento': objetoDepartamento.prcAumento,
            // Se hardcodean transferencia y conekta para realizar registro de reservacion
            'nomTipoPago': "bank_transfer", //data.charge.paymentMethod.type,
            'desPasarela': 1 //data.id,
        }
        try {
            const response = await reservationService.guardarReservacion(requestMovimiento);
            setLoading(false)
            if (response.data.success) {
                showAlertMessage(response.data.message, messageType.SUCCESS)
                setTextFieldValue({nomCliente: "", desTelefono: "", desCorreo: ""})
                setTimeout(function(){
                    navigate('/reserva')
                }, 3000);
            } else {
                showAlertMessage(Constants.GENERIC_MESSAGE, messageType.WARNING)
            }
        } catch (error) {
            setLoading(false)
            showAlertMessage(Constants.GENERIC_MESSAGE, messageType.ERROR)
        }
    };

    const regresaDetalle = () => {
        functions.reloadComponent()
    }

    useEffect(() => {
        let departamento = location.state || {}
        if (departamento) {
            setObjetoDepartamento({
                idDepto: departamento.id_departamento,
                tituloDepartamento: departamento.des_titulo,
                desDepartamento: departamento.des_departamento,
                impCosto: departamento.imp_costo_diario,
                impMtto: departamento.imp_costo_mantenimiento,
                fotografias: departamento.array_fotos.map((foto) => ({
                    img: `data:image;base64,${foto.imageBase64}`
                })),
                servicios: departamento.array_servicios,
                impCostoTotal: departamento.imp_costo_diario + departamento.imp_costo_mantenimiento,
                totalNoches: 1,
                numHuespedes: departamento.num_huesped,
                desReglas: departamento.des_reglas,
                desSeguridad: departamento.des_seguridad_propiedad,
                desPoliticas: departamento.des_politicas,
                coordIniciales: departamento.coordIniciales
            })
            setObjetoUbicacion({
                latitud: departamento.latitud,
                longitud: departamento.longitud,
            })
        } 
    }, [location.state]);
    return (
        <Grid container spacing={2}>
            <Grid item xs={4} md={2} alignSelf='center' ml={2}>
                <img
                    src='../../Assets/stayin_logo_combined.png'
                    sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}
                    style={{
                        width: 'auto',
                        height: 75,
                        display: 'block',
                    }}
                    onClick={() => navigate('/reserva')}
                    alt="StayIn Logo"
                />
            </Grid>
            <Grid item xs={6} md={8} ml={3} mr={3}>
                <Typography variant="h4" align='left' paragraph color="text.secondary" mt={1}>
                    {objetoDepartamento.tituloDepartamento}
                </Typography>
                <Typography variant="subtitle1" align='left' paragraph color="text.secondary">
                    {objetoDepartamento.numHuespedes} huespedes
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12} >
                { !usuarioReserva && /* Validamos si el usuario selecciono reservar para mostrar el detalle, si no mostramos el formulario de reserva*/ 
                    <Grid container spacing={2} alignItems="center" justifyContent="center">
                        <Grid item xs={12}>                        
                            <CustomImageList itemData={objetoDepartamento.fotografias} />
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="h6" align='left' paragraph color="text.secondary" mt={1} ml={3}>
                                {objetoDepartamento.desDepartamento}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Paper variant="elevation" elevation={4} square={false} style={{
                                    padding:10
                                }}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography variant="h6" paddingLeft={4} color="text.secondary" mt={1}>
                                                {`$${Math.floor(objetoDepartamento.impCosto).toLocaleString()} noche`}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" paddingLeft={4} marginBottom={2} color="text.secondary" mt={1}>
                                                Costo mantenimiento: {`$${Math.floor(objetoDepartamento.impMtto).toLocaleString()}`}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <CustomDatePicker
                                                label="Fecha llegada"
                                                minDate={dayjs()}
                                                maxDate={fechaFinal}
                                                initialValue={fechaInicial}
                                                slots={{
                                                    openPickerButton: () => null            
                                                }}
                                                onChange={(date) => setFechaInicial(date)}/>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <CustomDatePicker
                                                label="Fecha salida"
                                                minDate={fechaInicial.add(1, 'day')}
                                                initialValue={fechaFinal}
                                                slots={{
                                                    openPickerButton: () => null            
                                                }}
                                                onChange={calculoImportes}/>
                                        </Grid>
                                        <Grid item xs={12} marginTop={2}>
                                            <Typography variant="h6" paddingLeft={4} color="text.secondary" mt={1}>
                                                Total noches: {objetoDepartamento.totalNoches}
                                            </Typography>
                                            <Typography variant="h5" paddingLeft={4} color="text.secondary" mt={1}>
                                                Costo total: {`$${Math.floor(objetoDepartamento.impCostoTotal).toLocaleString()}`}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} align="center" mt={2}>
                                            <Button size='large' style={{backgroundColor: Constants.APP_COLOR, textTransform: 'none'}} variant='contained'  onClick={() => setUsuarioReserva(true)}>Reserva ahora!</Button>
                                        </Grid>
                                    </Grid>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={5}>
                            <Typography variant="h5" align='center' color="text.secondary" mt={1}>
                                Servicios
                            </Typography>
                            <ServiciosList servicios={objetoDepartamento.servicios} />
                        </Grid>
                        <Grid item xs={7}>
                            <Typography variant="h5" align='center' color="text.secondary" mt={1} mb={1}>
                                Ubicación
                            </Typography>
                            <Map draggable={false} onLocationSelect={() => {}} coordIniciales={objetoDepartamento.coordIniciales} markerCoord={{lat: parseFloat(objetoUbicacion.latitud), lng: parseFloat(objetoUbicacion.longitud)}} />
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} ml={3}>
                            <Typography variant="h5" color="text.secondary" mt={1} mb={1}>
                                Información importante
                            </Typography>
                        </Grid>
                        <Grid item xs={4} alignSelf="flex-start">
                            <Grid container ml={3}>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle1" color="text.secondary" mt={1} mb={1}>
                                        Reglas de la casa
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body2" component='div' color="text.secondary" mt={1} mb={1}>
                                        {ReactHtmlParser(objetoDepartamento.desReglas)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4} alignSelf="flex-start">
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle1" color="text.secondary" mt={1} mb={1}>
                                        Seguridad y propiedad
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body2" component='div' color="text.secondary" mt={1} mb={1}>
                                        {ReactHtmlParser(objetoDepartamento.desSeguridad)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4} alignSelf="flex-start">
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle1" color="text.secondary" mt={1} mb={1}>
                                        Política de cancelación
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} alignContent='start'>
                                    <Typography variant="body2" component='div' color="text.secondary" mt={1} mb={1}>
                                        {ReactHtmlParser(objetoDepartamento.desPoliticas)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid> 
                }
                { usuarioReserva &&
                    <Grid container spacing={2} mt={1}>
                        <Grid item xs={12} sm container ml={3}>                        
                            <Grid item xs={12} md={12} container direction="column" spacing={2} mr={2} ml={2}>
                            <Paper variant="elevation" elevation={4} square={false} style={{padding:10, marginBottom:10}}>
                                    <Grid item>
                                        <Typography variant="h6" paddingLeft={4} color="text.secondary" mt={1}>
                                            Resumen de tu reservación:
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography component='div' variant="body1" paddingLeft={4} marginBottom={1} color="text.secondary" mt={1}>
                                            <Box fontWeight='fontWeightMedium' display='inline'>
                                                Fecha de llegada: 
                                            </Box>
                                            {` ${fechaInicial.format("DD/MM/YYYY")} `}
                                            <Box fontWeight='fontWeightMedium' display='inline'>
                                                Fecha de salida: 
                                            </Box>
                                            {` ${fechaFinal.format("DD/MM/YYYY")}`}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography component='div' variant="body1" paddingLeft={4} color="text.secondary">
                                            <Box fontWeight='fontWeightMedium' display='inline'>
                                                Total noches:
                                            </Box>
                                             {` ${objetoDepartamento.totalNoches}`}
                                        </Typography>
                                        <Typography variant="h5" paddingLeft={4} color="text.secondary" mt={1}>
                                            Costo total: {`$${Math.floor(objetoDepartamento.impCostoTotal).toLocaleString()}`}
                                        </Typography>
                                    </Grid>
                                </Paper>
                                <Typography variant='h6' color="text.primary" mt={2}>
                                    Datos de húesped
                                </Typography>
                                <Typography variant="subtitle1" align='left' paragraph color="text.secondary" mt={1}>
                                    Favor de llenar los campos requeridos *
                                </Typography>
                                
                                <CustomTextField
                                    required
                                    name="nomCliente"
                                    label="Nombre"
                                    value={nomCliente}
                                    inputProps={{ maxLength: 80 }}
                                    regex={Constants.ALPHA_NUMERIC_DASH_REGEX}
                                    onChange={onCambiaValorTxt}
                                />
                                <CustomTextField
                                    required
                                    name="desTelefono"
                                    label="Télefono"
                                    value={desTelefono}
                                    inputProps={{ maxLength: 20 }}
                                    regex={Constants.INTEGER_DASH_REGEX}
                                    onChange={onCambiaValorTxt}
                                />
                                <CustomTextField
                                    required
                                    name="desCorreo"
                                    label="Correo"
                                    value={desCorreo}
                                    inputProps={{ maxLength: 30 }}
                                    type="email"
                                    onChange={onCambiaValorTxt}
                                />
                                 <Grid item xs align='right'>
                                    <Button
                                        size='large'
                                        variant='contained'
                                        style={{backgroundColor: Constants.GRAY_COLOR, textTransform: 'none', marginRight: 20}}
                                        onClick={regresaDetalle}>
                                        <Typography align='center' color="text.primary">
                                            Regresar
                                        </Typography>
                                    </Button>
                                    
                                    <Button
                                        size='large'
                                        style={{backgroundColor: Constants.APP_COLOR, textTransform: 'none'}}
                                        variant='contained'
                                        onClick={generarPago}>Guardar reservacion</Button>
                                </Grid>
                            </Grid>
                            {/* SE COMENTA FUNCIONALIDAD PARA UNA SEGUNDA FASE
                            <Grid item xs={12} md={6}>
                                {
                                    objetoConekta &&
                                    <CheckoutConekta idCheckout={objetoConekta.id_checkout} onFinalizePayment={onFinalizaPagoConekta}/>
                                }
                                
                            </Grid> */}
                        </Grid>
                        
                    </Grid>
                }
            </Grid>
            <Grid item xs={12} align="right" mr={3} mb={3}>
                {
                    !usuarioReserva &&
                    <Button size='large' style={{backgroundColor: Constants.APP_COLOR, textTransform: 'none'}} variant='contained' onClick={() => setUsuarioReserva(true)}>Reserva ahora!</Button>
                }
            </Grid >
            <CustomAlert ref={alertRef} />
            <Loading open={loading} handleClose={() => setLoading(false)} />
        </Grid>
    )
}

export default ReservationDetail;
